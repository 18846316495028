import styled from "styled-components";
import ImgBg from "../../Assets/images/homebg1.png";
import { COLORS, FONTS, responsive } from "../../Styles/Constants";
import NeverWinter from "Assets/images/Home/logo.png";
import HomeDescriptionBackgroundImage from "Assets/images/Home/home-description-background.png";
import NeverWinterLogo from "Assets/images/Home/NeverWinterLogo.webp";
import StoryImage from "Assets/images/Home/story.png";
import StoryBackgroundImage from "Assets/images/Home/StoryBackgroundImage.png";
import RaceBackgroundImage from "Assets/images/Home/RaceBackgroundImage.png";
import ClassesBackgroundImage from "Assets/images/Home/ClassesBackgroundImage.jpg";
import Border from "Assets/images/Home/border.png";
import RacesBorder from "Assets/images/Home/RacesBorder.png";
// import Barovia from "Assets/images/Realms/Barovia.png";
import Barovia1 from "Assets/images/Realms/Barovia1.jpg";
// import Chult from "Assets/images/Realms/Chult.png";
import Chult1 from "Assets/images/Realms/Chult1.jpg";
// import Icespire_1 from "Assets/images/Realms/Icespire_1.png";
import Icespire1 from "Assets/images/Realms/Icespire1.jpg";
// import Avernus_2 from "Assets/images/Realms/Avernus_2.png";
import Avernus1 from "Assets/images/Realms/Avernus1.jpg";
// import Sharandar from "Assets/images/Realms/Sharandar.png";
import Sharandar1 from "Assets/images/Realms/Sharandar1.jpg";
// import Underdark_2 from "Assets/images/Realms/Underdark_2.png";
import Underdark1 from "Assets/images/Realms/Underdark1.jpg";
import Class1 from "Assets/images/Class Icons/1.svg";
import Class2 from "Assets/images/Class Icons/2.svg";
import Class3 from "Assets/images/Class Icons/3.svg";
import Class4 from "Assets/images/Class Icons/4.svg";
import Class5 from "Assets/images/Class Icons/5.svg";
import Class6 from "Assets/images/Class Icons/6.svg";
import Class7 from "Assets/images/Class Icons/7.svg";
import Class8 from "Assets/images/Class Icons/8.svg";
import Class9 from "Assets/images/Class Icons/9.svg";
import ArrowIcon from "Assets/images/Home/Races/arrow.png";
import SpecialSymbol from "Assets/images/Home/Races/special_symbol.svg";
import SteamSvg from "Assets/images/Home/steam.svg";

export const HomeContainer = styled.div.attrs({
  className: 'HomeContainer',
})`
  height: clamp(450px, 80vh, 9999px);;
  z-index: 1;
  filter: brightness(0.9);

  ${responsive.LAPTOP`
    height : clamp(450px, 80vh, 9999px);
  `}
  ${responsive.TABLET`
    height : clamp(450px, 60vh, 2000px);
  `}
  ${responsive.PHABLET`
    height : clamp(450px, 35vh, 2000px);
  `}
  ${responsive.MOBILE`
    // height : 45vh;
  `}
  
  .bg-image {
    background: url(${ImgBg});
    background-position: center right;
    background-size: cover;
    height: 100%;
    /* background-repeat: no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .image {
      background: url(${NeverWinter});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 600px;
      height: 100px;
      z-index: 1;
      ${responsive.TABLET`
        width: 310px;
      `}
    }

    .text {
      font-size: 20px;
      font-family: ${FONTS.PRIMARY};
      color: #fff;
      margin: 10px 0;
      padding: 0 20px;
      text-align: center;
      z-index: 1;

      ${responsive.TABLET`
        font-size : 16px;
      `}
    }

    .play-now {
      margin: 35px 0 15px;
      color: #fff;
      font-size: 18px;
      font-family: ${FONTS.GALDEANO};
      background-color: ${COLORS.SECONDARY};
      width: 300px;
      height: 50px;
      z-index: 1;
      border-radius: 2px;
      &:hover {
        background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
      }
      ${responsive.TABLET`
        margin: 16px;
        width : 200px;
        font-size : 16px;
      `}
    }

    .also-available-on-steam {
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
      font-size: 18px;
      font-family: ${FONTS.GALDEANO};
      background-color: rgba(0, 0, 0, .40);
      width: 300px;
      height: 50px;
      z-index: 1;
      border-radius: 2px;
      border: solid 1px rgba(224, 38, 50, .40);
      &:hover {
        background-color: rgba(0, 0, 0, 1);
        border: solid 1px rgba(224, 38, 50, 1);
        color: rgba(255, 255, 255, 1);
      }
      ${responsive.TABLET`
        margin: 16px;
        width : 200px;
        font-size : 16px;
      `}
    }

    .also-available-on-steam--de,
    .also-available-on-steam--fr,
    .also-available-on-steam--it,
    .also-available-on-steam--ru {
      ${responsive.TABLET`
        font-size : 12px;
      `}
      
    }

    .also-available-on-steam__steam-icon {
      background-image: url(${SteamSvg});
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      background-position: center;
      margin-left: 8px;
      background-size: contain;
    }

    .video-viewport {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const DescriptionContainer = styled.div.attrs({
  className: 'DescriptionContainer',
})`
  .bg-image {
    margin-top: -70px;
    z-index: 10;
    filter: brightness(1);
    background: url(${HomeDescriptionBackgroundImage});
    background-position: center top;
    background-size: cover;
    height: max-content;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;

    .container {
      display: flex;
      /* justify-content: center;  */
      align-items: center;
      width: 100%;
      ${responsive.TABLET`
        flex-direction : column;
      `}
      .left-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: left;

        ${responsive.TABLET`
          width : 100%;
          align-items : center;
        `}

        .image {
          background: url(${NeverWinterLogo});
          background-position: center;
          background-size: cover;
          height: 169px;
          width: 300px;
          background-repeat: no-repeat;
        }

        .text {
          font-size: 20px;
          font-family: ${FONTS.PRIMARY};
          width: 100%;
          padding: 20px 20px 20px 0;

          ${responsive.TABLET`
            width : 80%;
            font-size : 16px;
            text-align : center;
          `}

          ${responsive.PHABLET`
            width: 100%;
          `}
        }

        .learn-more {
          margin: 35px 0;
          color: #fff;
          font-size: 18px;
          font-family: ${FONTS.GALDEANO};
          background-color: ${COLORS.SECONDARY};
          width: 300px;
          height: 50px;
          border-radius: 2px;

          &:hover {
            background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
          }
          ${responsive.TABLET`
            display : none;
          `}
        }
      }
    }

    .learn-more-mobile {
      display: none;
      margin: 35px 0;
      color: #fff;
      font-size: 18px;
      font-family: ${FONTS.GALDEANO};
      background-color: ${COLORS.SECONDARY};
      width: 300px;
      height: 50px;
      border-radius: 2px;
      text-align: center;

      &:hover {
        background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
      }

      ${responsive.TABLET`
          display : block;
        `}
      ${responsive.PHABLET`
          width : 280px;
        `}
        ${responsive.MOBILE`
          width : 250px;
        `}
    }

    .right-container {
      width: 50%;
      display: flex;
      justify-content: center;
      ${responsive.TABLET`
        width : 100%;
        margin : 30px 0 10px;
        padding: 0 20px;
      `}
      .image-container {
        width: 100%;
        height: 100%;
      }
    }

    ${responsive.MOBILE`
      margin-top: -27px;
    `}
  }
`;

export const StoryContainer = styled.div.attrs({
  className: 'StoryContainer',
})`
  .bg-image {
    background: url(${StoryImage});
    background-position: center;
    background-size: cover;
    height: 600px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .container {
      background: url(${StoryBackgroundImage});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 1200px;
      height: 325px;
      text-align: center;
      /* background-color: #fff; */

      ${responsive.LAPTOP`
        width : 950px;
      `}
      ${responsive.TABLET`
        width: calc(100% - 40px);
        margin: auto;
      `}
      ${responsive.PHABLET`
        height : max-content;
      `}
      ${responsive.MOBILE`
        max-width : 380px;
      `}
      .title {
        font-size: 45px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY};
        padding: 50px 0;
        justify-content: center;
        display: flex;
        color: ${COLORS.GOLDEN_TITLE_COLOR};

        ${responsive.TABLET`
          font-size : 32px;
          padding : 50px 8% 0;
        `}
      }

      .text {
        font-size: 20px;
        letter-spacing: 1px;
        padding: 0 8%;
        text-align: center;

        ${responsive.TABLET`
          padding : 20px 8% 30px;
          font-size : 16px;
        `}

        ${responsive.PHABLET`
          padding-bottom: 50px;
        `}
      }
    }
    .border-box {
      width: 300px;
      height: 250px;

      ${responsive.TABLET`
        width : 250px;
        height : 200px;
      `}
      ${responsive.MOBILE`
        width : 200px;
      `}
      &.top {
        border-top: 3px solid #c0a873;
        border-right: 3px solid #c0a873;
        transform: translate(291%, 109%);

        ${responsive.LAPTOP`
          transform : translate(207%,109%);
        `}
        ${responsive.TABLET`
          transform : translate(130%,111%);
        `}
        ${responsive.PHABLET`
          transform : translate(60%,108%);
        `}
        ${responsive.MOBILE`
          transform : translate(45%,107%);
        `}
      }
      &.bottom {
        border-bottom: 3px solid #c0a873;
        border-left: 3px solid #c0a873;
        transform: translate(10%, -109%);

        ${responsive.LAPTOP`
          transform : translate(9%,-109%);
        `}
        ${responsive.TABLET`
          transform : translate(8%,-113%);
        `}
        ${responsive.PHABLET`
          transform : translate(6%,-108%);
        `}
        ${responsive.MOBILE`
          transform : translate(7%,-107%);
        `}
      }
    }
  }
`;

export const RaceWrapper = styled.div.attrs({
  className: 'RaceWrapper',
})`
  .bg-image {
    background: url(${RaceBackgroundImage});
    background-position: center top;
    background-size: cover;
    height : ${props => props.language !== 'ru' ? "350px" : "370px"};
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    ${responsive.MOBILE`
      height : ${props => props.language !== 'ru' ? "300px" : "350px"};
    `}
    .content {
      width: 50%;

      ${responsive.TABLET`
        width : 100%;
      `}
      .container {
        display: flex;
        flex-direction: column;
        padding: 50px 0 0;
        width: 100%;

        ${responsive.TABLET`
          width: 100%;
          padding: 0 20px;
        `}
        .title {
          font-size: 45px;
          text-transform: uppercase;
          margin: 20px auto;
          color: ${COLORS.GOLDEN_TITLE_COLOR};

          ${responsive.PHABLET`
            font-size : 36px;
          `}
          ${responsive.MOBILE`
            font-size : 24px;
          `}
        }

        .border {
          background: url(${RacesBorder});
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 18px;
          /* margin : 20px 0 0; */
          /* background: linear-gradient(
            to right,
            #745d44 21%,
            #a89164 49%,
            #bca87a 82%,
            #745d44 100%
          ); */

          ${responsive.TABLET`
            display : none;
          `}
        }

        .dropdown {
          display: none;
          justify-content: center;

          ${responsive.TABLET`
            display : flex;
          `}
          .races-dropdown-btn {
            width: 280px;
            border-radius: 4px;
            border-radius: 5px;
            border: 1px solid #666666;

            .end-icon {
              margin: 0 0 0 auto;
            }
          }
          .btn-icon {
            background-image: url(${ArrowIcon});
            height: 15px;
            width: 20px;
            background-repeat: no-repeat;
            margin: 0 0 0 auto;
            background-size: 100%;
          }
        }

        .container {
          display: none;
          align-items: flex-start;
          flex-direction: column;
          margin: 0 auto;
          width: 100%;
          padding: 0 20px;
          ${responsive.TABLET`
            display: flex;
          `}
          .title {
            font-size: 24px;
            color: #fff;
            margin: 20px auto 0;
            color: #000;
            text-transform: uppercase;

            ${responsive.PHABLET`
              font-size : 18px;
            `}
            ${responsive.PHABLET`
              font-size : 16px;
            `}
          }
          .desc {
            font-size: 18px;
            color: #333333;
            margin: 5px 0;
            ${responsive.PHABLET`
              font-size : 16px;
            `}
          }
        }

        .tags {
          display: flex;
          margin: 10px auto;

          ${responsive.TABLET`
            display : none;
          `}
          .key {
            margin: 0 5px;
            text-transform: uppercase;
            width: max-content;
            display: flex;
            align-items: center;

            .zen-icon {
              background-image: url(${SpecialSymbol});
              height: 10px;
              width: 10px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              margin: 0 5px 0 0;
            }

            ${responsive.LAPTOP`
              width : auto;
            `}

            &:hover {
              color: #2d448a;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          .dummy-div {
            width: 2px;
            background-color: #c0a873;
            margin: 0 5px;

            &:last-child {
              display: none;
            }
          }
        }
      }
    }

    @media (min-width: 1630px) {
      height: 433px;
    }

    @media (min-width: 2030px) {
      height: 558px;
    }
  }

  .information-container {
    height: 324px;
    width: 100%;
    background: transparent linear-gradient(180deg, #314585 0%, #1b2446 100%) 0%
      0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    padding-top: 20px;

    ${responsive.MOBILE`
        height : 150px;
      `}
    .content {
      width: 50%;
      height: 100%;

      ${responsive.MOBILE`
        width : 100%;
      `}
      .container {
        padding: 60px 0 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 40px;
        width: 90%;
        ${responsive.TABLET`
          display : none;
        `}
        .title {
          font-size: 60px;
          color: #fff;
          text-transform: uppercase;

          ${responsive.LAPTOP`
            font-size : 45px;
          `}
        }
        .desc {
          font-size: 20px;
          color: #fff;
          margin: 40px 0;

          ${responsive.TABLET`
            font-size : 16px;
          `}
        }
      }
    }

    .image {
      height: 600px;
      width: 600px;
      margin: -600px -1% 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;

      ${responsive.DESKTOP`
        margin: -600px 1% 0 auto;
      `}
      ${responsive.LAPTOP`
        width : 500px;
        height : 500px;
        margin : -500px 0 0 auto;
      `}
      ${responsive.TABLET`
        width : 380px;
        height : 380px;
        margin : -380px auto;
      `}
      ${responsive.MOBILE`
        width : 200px;
        height : 200px;
        margin : -201px auto;
      `}
    }
    .border {
      background: url(${Border});
      background-repeat: no-repeat;
      height: 338px;
      width: calc(100% - 20px);
      margin-top: -300px;

      ${responsive.LAPTOP`
        // transform : translate(0%,-91%);
        // margin-top: -173px;

      `}
      ${responsive.TABLET`
        // transform : translate(9%,40%);
        margin-top: 80px;
      `}
      ${responsive.PHABLET`
        // transform : translate(4%,40%);
        
      `}
      ${responsive.MOBILE`
        // transform : translate(4%,8%);
        // height : 200px;
        margin-top: 153px;
        position: relative;
        top: -245px;
      `}
    }
  }
`;

export const PacedContainer = styled.div.attrs({
  className: 'PacedContainer',
})`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  .video-viewport {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .bg-image {
    background-position: center;
    background-size: cover;
    height: 600px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 2;

    .container {
      background: url(${StoryBackgroundImage});
      z-index: 3;
      padding: 16px;
      width: 1200px;
      height: 345px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;

      ${responsive.LAPTOP`
        width : 920px;
      `}
      ${responsive.TABLET`
        width : 550px;
      `}
      ${responsive.PHABLET`
        width : 400px;
        height : max-content;
      `}
      ${responsive.MOBILE`
        width: calc(100% - 40px);
        max-width : 380px;
      `}
      .title {
        font-size: 45px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY};
        margin: 20px 0;
        justify-content: center;
        display: flex;
        color: ${COLORS.GOLDEN_TITLE_COLOR};

        ${responsive.LAPTOP`
          font-size: 32px;
        `}

        ${responsive.TABLET`
          font-size : 20px;
          margin : 50px 0 0;
          text-align:center;
          justify-content:center;
        `}
      }

      .text {
        font-size: 20px;
        letter-spacing: 1px;
        margin: 32px 8%;
        text-align: center;

        ${responsive.TABLET`
          margin : 20px 8% 30px;
          font-size : 16px;
        `}
      }
    }
    .border-box {
      width: 300px;
      height: 250px;
      z-index: 4;
      ${responsive.MOBILE`
        width : 200px;
      `}
      &.top {
        border-top: 2px solid #c0a873;
        border-right: 2px solid #c0a873;
        transform: translate(143%, 115%);

        ${responsive.LAPTOP`
          transform : translate(95%,115%);
        `}
        ${responsive.TABLET`
          transform : translate(35%,115%);
        `}
        ${responsive.PHABLET`
          transform : translate(11%,115%);
        `}
        ${responsive.MOBILE`
          transform : translate(21%,115%);
        `}
      }
      &.bottom {
        border-bottom: 2px solid #c0a873;
        border-left: 2px solid #c0a873;
        transform: translate(-142%, -114%);

        ${responsive.LAPTOP`
          transform : translate(-96%,-114%);
        `}
        ${responsive.TABLET`
          transform : translate(-35%,-113%);
        `}
        ${responsive.PHABLET`
          transform : translate(-11%,-113%);
        `}
        ${responsive.MOBILE`
          transform : translate(-20%,-113%);
        `}
      }
    }
  }
`;

export const ClassesContainer = styled.div.attrs({
  className: 'ClassesContainer',
})`
  background: url(${ClassesBackgroundImage});
  background-position: center top;
  background-size: cover;
  height: max-content;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  margin-bottom: -11px;

  .content {
    width: 100%;
  }
  .container {
    width: 100%;
    min-height: max-content;
    padding: 40px 20px;
    text-align: center;
    position: relative;
  }

  p {
    min-height: max-content;
    padding: 15px;
    font-size: 20px;

    ${responsive.TABLET`
      font-size : 16px;
    `}
    ${responsive.MOBILE`
      width:120%;
      margin:-10% -10% 0;
    `}
  }

  .border {
    background: url(${Border});
    background-repeat: no-repeat;
    transform: rotate(180deg);
    position: absolute;
    height: 335px;
    width: calc(100% - 20px);
    margin: 0;

    ${responsive.MOBILE`
      height: 380px;
    `}
  }

  .title {
    font-size: 45px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 40px 0 20px;
    letter-spacing: 0.1em;
    min-height: max-content;
    color: ${COLORS.GOLDEN_TITLE_COLOR};

    ${responsive.MOBILE`
        padding : 20px 0 30px;
        margin-top:90px;
        font-size:24px
    `}
  }

  .descriptor {
    min-height: max-content;
    padding: 15px;
    font-size: 20px;
    letter-spacing: 0.05em;

    ${responsive.TABLET`
      font-size : 16px;
    `}
  }
  .travel-images {
    width: 100%;
    height: max-content;
    margin: 20px 0 0;
  }

  .inner-images {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    ${responsive.PHABLET`
      display:flex;
      flex-direction:column;      
    `}
  }

  .barovia,
  .sharandar,
  .jungles,
  .icesprire,
  .avernus,
  .underdark {
    height: 330px;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% / 6);
    transition: all 0.5s ease;

    ${responsive.PHABLET`
      width:100%;
      height:120px;
      background-size:cover;
      background-repeat:no-repeat;    
      display : flex;
      align-items : center;
    `}

    &:hover {
      width: 100%;
    }
    span {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      opacity: 0;
      color: #fff;
      position: relative;
      transition: all 0.5s ease-in-out;

      ${responsive.PHABLET`
        opacity: 1;
        letter-spacing: 0;
        font-size: 35px;
        height: max-content;
      `}
    }
  }

  .barovia:hover span,
  .sharandar:hover span,
  .jungles:hover span,
  .icesprire:hover span,
  .avernus:hover span,
  .underdark:hover span {
    opacity: 0.9;
    letter-spacing: 2px;
  }

  .barovia {
    background-image: url(${Barovia1});
  }
  .sharandar {
    background-image: url(${Sharandar1});
  }

  .jungles {
    background-image: url(${Chult1});
  }

  .icesprire {
    background-image: url(${Icespire1});
  }

  .avernus {
    background-image: url(${Avernus1});
  }

  .underdark {
    background-image: url(${Underdark1});
  }

  .content {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .classes {
    width: 100%;
    margin: 50px auto 20px;
    justify-content: center;
    display: flex;
    position: relative;
    font-size: 45px;
    letter-spacing: 4.5px;
    text-transform: uppercase;
    font-family: ${FONTS.PRIMARY};
    color: ${COLORS.GOLDEN_TITLE_COLOR};

    ${responsive.MOBILE`
      font-size : 30px;
      text-align:center;
      justify-content:center;
      position:relative;
  `}
  }

  /* @media only screen and (min-width: 1960px) {
    .barovia,
    .sharandar,
    .jungles,
    .icesprire,
    .avernus,
    .underdark {
      display: flex;
      width: 200px;
      height: 100%;
      background-repeat: no-repeat;
      background-blend-mode: color-dodge;
      background-position: 16% 32% 48% 64% 80% 96%;
    }
    .barovia:hover,
    .sharandar:hover,
    .jungles:hover,
    .icesprire:hover,
    .avernus:hover,
    .underdark:hover {
      width: 220px;
      transition: all 0.5s ease;
    }
  }

  @media only screen and (min-width: 1600px) {
    .barovia,
    .sharandar,
    .jungles,
    .icesprire,
    .avernus,
    .underdark {
      display: flex;
      width: 200px;
      height: 100%;
      background-repeat: no-repeat;
      background-blend-mode: color-dodge;
      background-position: 16% 32% 48% 64% 80% 96%;
    }
    .barovia:hover,
    .sharandar:hover,
    .jungles:hover,
    .icesprire:hover,
    .avernus:hover,
    .underdark:hover {
      width: 220px;
      transition: all 0.5s ease;
    }
  } */

  .images {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    ${responsive.LAPTOP`
      width: 100%;
    `}
    /* ${responsive.TABLET`
      width: 80%;
    `} */

    .image-section {
      min-width: 150px;
      margin: 10px 0;

      ${responsive.LAPTOP`
        min-width : 160px;
      `}
      .image-name {
        text-align: center;
        margin: 5px 0;
        text-transform: uppercase;
      }
    }
  }
  .barbarian,
  .wizard,
  .warlock,
  .rogue,
  .bard,
  .paladin,
  .fighter,
  .ranger,
  .cleric {
    margin: 0 auto;
    opacity: 0.25;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .images .active {
    opacity: 1;
  }

  .barbarian {
    width: 99px;
    height: 126px;
    background-image: url(${Class1});
    background-position: center;
    background-repeat: no-repeat;
  }

  .wizard {
    width: 99px;
    height: 126px;
    background-image: url(${Class2});
    background-position: center;
    background-repeat: no-repeat;
  }
  .warlock {
    width: 99px;
    height: 126px;
    background-image: url(${Class3});
    background-position: center;
    background-repeat: no-repeat;
  }
  .rogue {
    width: 99px;
    height: 126px;
    background-image: url(${Class4});
    background-position: center;
    background-repeat: no-repeat;
  }

  .bard {
    width: 99px;
    height: 126px;
    background-image: url(${Class5});
    background-position: center;
    background-repeat: no-repeat;
  }

  .paladin {
    width: 99px;
    height: 126px;
    background-image: url(${Class9});
    background-position: center;
    background-repeat: no-repeat;
  }

  .fighter {
    width: 99px;
    height: 126px;
    background-image: url(${Class8});
    background-position: center;
    background-repeat: no-repeat;
  }

  .ranger {
    width: 99px;
    height: 126px;
    background-image: url(${Class6});
    background-position: center;
    background-repeat: no-repeat;
  }

  .cleric {
    width: 99px;
    height: 126px;
    background-image: url(${Class7});
    background-position: center;
    background-repeat: no-repeat;
  }

  .image .display-image2 {
    right: -50%;
  }
  .active {
    display: flex;
    width: 30%;
    justify-content: space-evenly;
    ${responsive.LAPTOP`
      width: 100%;
    `}
  }
  .images .active {
    width: auto;
  }

  .inactive {
    display: none;
  }

  .class-description {
    font-size: 14px;
  }

  .images .active .display-image,
  .images .active .display-image2,
  .active .display-image,
  .active .display-image2 {
    transition: 0.5s;
    width: 170px;
    height: 300px;
    /* display: block; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 0 600px; */
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    position: relative;
  }

  .images .active .display-image:hover,
  .images .active .display-image2:hover,
  .active .display-image:hover,
  .active .display-image2:hover {
    background-position: top center;
  }

  .images .active .display-image2,
  .active .display-image2 {
    /* margin: -300px 770px; */
  }
  /* .active .display-image:hover ,
   .active .display-image2:hover {
  
    top: -10px;
    margin-bottom:10px;
   
  } */

  .display-image .sentinel-details2,
  .display-image2 .sentinel-details2 {
    opacity: 0;
    transition: all 0.2s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    height: 100px;
    width: 130px;
    bottom: 0;
    font-size: 10px;
    p {
      padding-left: 0;
      font-size: 14px;
    }
  }

  .display-image:hover .sentinel-details2,
  .display-image2:hover .sentinel-details2 {
    opacity: 1;
  }

  .sentinel,
  .sentinel-details,
  .blademaster,
  .display-image,
  .display-image2,
  .sentinel-details3 {
    display: none;
  }

  .bbr {
    display: none;
  }
  .dropdown {
    display: none;
    justify-content: center;
    ${responsive.TABLET`
      display : none;
    `}
    ${responsive.MOBILE`
      display : flex;
    `}  
    .classes-dropdown-btn {
      width: 280px;
      height: 40px;
      margin-top: 20px;
      border-radius: 4px;
      border-radius: 5px;
      border: 1px solid #666666;
      .end-icon {
        margin: 0 0 0 auto;
      }
    }
    .btn-icon {
      background-image: url(${ArrowIcon});
      height: 15px;
      width: 20px;
      background-repeat: no-repeat;
      /* margin-right: 20px; */
      background-size: 100%;
    }
  }
  ${responsive.MOBILE`
  .content {
    display: block;
  }

  .barbarian {
    background: none;
  }

  .sentinel-details,
  .blademaster {
    display: block;
  }
  .images{ 
    display : none;
  }

  .bbr {
    width: 100%;
    display: block;
    font-size: 35px;
    margin: 10px auto;
    letter-spacing: 3px;
    text-align: center
  }

  .active {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .sentinel,
  .blademaster {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .sentinel-image,
  .bimg {
    width: 40%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 120px;
    }
  }

  .sentinel-details {
    width: 60%
  }

  .sentinel-details h2 {
    font-family: ${FONTS.PRIMARY};

  }

  .sentinel-details p {
    width: 100%;
    margin: 0;
    padding: 16px 0 0;
    font-size:15px;
    font-family: ${FONTS.PRIMARY};
  }

  .barbarian,
  .wizard,
  .warlock,
  .rogue,
  .bard,
  .paladin,
  .fighter,
  .ranger,
  .cleric {
     opacity: 1;
  }


  .wizard,
  .warlock,
  .rogue,
  .bard,
  .cleric,
  .paladin,
  .fighter,
  .ranger,
  .active .display-image,
  .active .display-image2 {
    display: none;
  }
`}
`;
